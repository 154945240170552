<script setup>
  const localePath = useLocalePath();
</script>

<template>
  <div id="top" class="mt-44 xs:mt-40 lg:mt-44">
    <NavigationCenteredSearch />
    <TitleBar><NavigationBreadcrumb /></TitleBar>
    <div class="pt-4 xs:p-4 sm:p-4"><slot /></div>
    <Footer />
    <UiBackToTopButton />
    <WebshopModalCart />
    <WebshopCurrencyModal />
    <AuthForgetPasswordModal />
    <!-- TODO átírni -->
    <!-- <ModalWebshopCurrencyModifiedInfo /> -->
    <!-- <ModalAuthForgetPassword /> -->
    <!-- <CookieBanner /> -->
    <LazyUiSnackBar/>
  </div>
</template>